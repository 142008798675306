/* @import url('https://use.typekit.net/ekx2dwn.css'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

@font-face {
  font-family: 'Apparat';
  src: local('Apparat'), url('/font/apparat.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'James';
  src: local('James'), url('/font/james.otf') format('opentype');
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #090909;
  --white: #ffffff;
  --background: #ffffff;
  --foreground: #000000;
  --light-gray: lightgray;
  --light-gray-disabled: #ebebeb;
  --gray-blue: #b5c4c3;
  --color-issue: #e4ff3c;
  --color-branding: #f36ff5;
  --color-education: #05f600;
  --nav-height: 49px;
  /* --font-aspekta: Aspekta, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  --font-cs: Apparat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-sovrumana: James, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-sans: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

::selection {
  color: var(--black);
  background-color: var(--color-branding);
  opacity: 0.5;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-aspekta);
  background-color: var(--black);
  color: var(--white);
  overscroll-behavior-y: none;
}

@layer base {
  a {
    color: inherit;
  }
  * {
    box-sizing: border-box;
    backface-visibility: visible;
    /* letter-spacing: -0.8px; */
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
    letter-spacing: -0.25px;
    line-height: 0.9;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.3125rem;
  }
  input {
    border-radius: 0px;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
      line-height: 1.125;
    }
  }
}

@layer components {
  .blog-text > * {
    display: block;
  }
  .blog-text > h2,
  .blog-text > h3,
  .blog-text > h4,
  .blog-text > h5,
  .blog-text > h6 {
    @apply mt-10 mb-6;
  }
  .blog-text > h2 {
    font-size: 3rem;
  }
  .blog-text > h3 {
    font-size: 2.3125rem;
  }
  .blog-text > p {
    line-height: 1.5;
    letter-spacing: 0.12px;
    margin-bottom: 1.5rem;
  }
  .blog-text a {
    text-decoration: underline;
    font-weight: 600;
  }
  .blog-text img {
    width: 100%;
    height: auto;
    @apply my-4;
  }

  .signup-form input {
    @apply border-white px-2 bg-transparent border-b-2 placeholder-white placeholder-opacity-100;
  }
  .signup-form button {
    @apply bg-white text-black hover:cursor-pointer active:border-gray active:bg-gray active:text-foreground;
  }

  .signup-form.light input {
    @apply border-black px-2 bg-transparent border-b-2 border-b-foreground placeholder-foreground placeholder-opacity-100;
  }
  .signup-form.light button {
    @apply bg-foreground text-background hover:cursor-pointer active:border-gray active:bg-gray active:text-foreground;
  }

  .product-description h2,
  .product-description h3 {
    line-height: 1.2;
  }
  .product-description h2 {
    margin-bottom: 1rem;
  }
  .product-description h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
  .product-description p {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 768px) {
    .blog-text > h1,
    .blog-text > h2,
    .blog-text > h3,
    .blog-text > h4,
    .blog-text > p {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .blog-text > p:has(img) {
      padding: 0;
    }
  }

  .rich-text p {
    margin-bottom: 1.25rem;
  }
  .rich-text h1,
  .rich-text h2,
  .rich-text h3,
  .rich-text h4,
  .rich-text h5 {
    font-size: 2.5rem;
    margin-bottom: 1.75rem;
  }
  .rich-text ul > li {
    margin-bottom: 1rem;
  }

  /*  */
  /* LENIS */
  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }
  /* END-LENIS */
  /*  */

  .the-card {
    background-color: transparent;
  }

  .the-card-inner {
    position: relative;
    text-align: center;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .the-card-front,
  .the-card-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .the-card-front {
    background: url('/resources/card-front.webp');
    background-size: cover;
    background-position: center;
  }

  .the-card-back {
    transform: rotateY(180deg);
    background: url('/resources/card-back.webp');
    background-size: cover;
    background-position: center;
  }
}

/* Customzing Swiper's prev/next arrows */
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
}

/* .cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  max-width: 72px;
  max-height: 72px;
  transform: translate3d(-100vw, -100vh, 0);
  pointer-events: none;
  z-index: 9999;
  visibility: hidden;
}

 */

@media (prefers-color-scheme: dark) {
  :root {
    --background: #000000;
    --foreground: #ffffff;
    --light-gray: lightgray;
    --light-gray-disabled: #ebebeb;
    --font-cs: Apparat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    --font-sovrumana: James, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
  }

  html,
  body {
    background-color: var(--white);
    color: var(--black);
  }
}
